import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import styles from "../styles/rota-caldeirao.module.scss"

import Layout from "../components/Layout"
import PageCover from "../components/PageCover"

const Freguesia = () => {
  const data = useStaticQuery(graphql`
    query {
      getCoverImage: file(relativePath: { eq: "rota-caldeirao/cover.png" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      getFirstDescriptionImage: file(
        relativePath: { eq: "rota-caldeirao/description-1.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      getSecondDescriptionImage: file(
        relativePath: { eq: "rota-caldeirao/description-2.jpg" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <PageCover
        image={
          <Img
            fluid={data.getCoverImage.childImageSharp.fluid}
            alt="Fachada de casa na freguesia do Touro"
            style={{ height: "100%", width: "100%" }}
            imgStyle={{ objectFit: "cover" }}
          />
        }
        title="Rota do Caldeirão"
      />
      <div className={styles.wrapper}>
        <div className={styles.description}>
          <h1>Caminho antigo</h1>
          <p>
            A Rota do Caldeirão é caminho antigo, de moleiros, levados a espojar
            cereal, trigo e milho, com eiras e palhais. O Caldeirão, que lhe dá
            o nome, é cascata onde pousam esses moinhos, alimentadas as pedras
            redondas pela voragem das águas, marcadas pelas cortes, os
            pardieirinhos, onde se guardava o gado.
          </p>
        </div>
        <div className={styles.imagesWrapper}>
          <div className={styles.image}>
            <Img
              fluid={data.getFirstDescriptionImage.childImageSharp.fluid}
              alt="People diving at the river from Rota do Caldeirão"
            />
          </div>
          <div className={styles.image}>
            <Img
              fluid={data.getSecondDescriptionImage.childImageSharp.fluid}
              alt="Water course from Rota do Caldeirão"
            />
          </div>
        </div>
        <div className={styles.description}>
          <h1>Natureza intocada</h1>
          <p>
            Toda esta transumância se vivencia neste circuito natural, de onde
            se observa uma natureza ainda intocada, o rio de trutas e bogas,
            alimentado por um quarteto de ribeiros, descidos do alto da serra da
            Nave para o planalto, banhando prados, bosques e campos de lavoura.
            O rio, bordejado por amieiros e salgueiros, força da água para
            empurrar o granito das mós, aqui e além alguns carvalhos e uma
            natureza de habitats, em plena Rede Natura.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Freguesia
